import React from "react";
import { Typography } from "antd";
const { Title, Paragraph } = Typography;

interface Props {
  queueSize: number;
  queueIndex: number;
  total: number;
  showDetails: boolean;
}

const Dash = ({ queueSize, queueIndex, total, showDetails = true }: Props) => {
  return (
    <div>
      {showDetails && (
        <>
          <Title level={4}>本次任务：{queueSize}</Title>
          <Paragraph>当前索引：{queueIndex + 1}</Paragraph>
          <Paragraph>剩余人数：{queueSize - queueIndex}</Paragraph>
        </>
      )}
      <Title level={4}>汇总：</Title>
      <Paragraph>总任务：{total}</Paragraph>
      <Paragraph>已完成：{total - queueSize + queueIndex}</Paragraph>
    </div>
  );
};

export default Dash;
