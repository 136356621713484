import React from "react";
import { MdExitToApp } from "react-icons/md";
import { useLogout, useUserName } from "state/hooks";
import styles from "./index.module.scss";

const LoginStatus = () => {
  const userName = useUserName();
  const logout = useLogout();

  return userName ? (
    <div className={styles["login-status"]}>
      <span>您好，{userName}</span>
      <div className={styles.logout} onClick={logout}>
        退出
        <MdExitToApp />
      </div>
    </div>
  ) : null;
};

export default LoginStatus;
