import React from "react";
import { Form, Field } from "react-final-form";
import {
  Row,
  Col,
  Input,
  Button,
  Space,
  DatePicker,
  Checkbox,
  Radio,
  Typography,
} from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
// import locale from "antd/es/date-picker/locale/zh_CN";
import moment from "moment";
import { provinces } from "config";
import { startInterview } from "api";
import styles from "./index.module.scss";

const { Paragraph, Text } = Typography;

const cols = { first: 6, second: 18 };

interface Props {
  id: string;
  obj?: ActivityFormModel;
  onSubmit: (values: ActivityFormModel) => Object | void;
  token: string;
}
const Details = ({ id, obj, onSubmit, token }: Props) => (
  <Form onSubmit={onSubmit} initialValues={obj ?? {}}>
    {({ handleSubmit, form, values }) => (
      <form onSubmit={handleSubmit} className={styles.details}>
        <Space direction="vertical" style={{ width: "100%" }}>
          <Row>
            <Col sm={cols.first}>编号</Col>
            <Col sm={cols.second}>{id}</Col>
          </Row>
          <Field name="name">
            {({ input, meta }) => (
              <Row>
                <Col sm={cols.first}>名称</Col>
                <Col sm={cols.second}>
                  <Input {...input} placeholder="名称" />
                </Col>
              </Row>
            )}
          </Field>
          <Field name="description">
            {({ input, meta }) => (
              <Row>
                <Col sm={cols.first}>描述</Col>
                <Col sm={cols.second}>
                  <Input {...input} placeholder="描述" />
                </Col>
              </Row>
            )}
          </Field>
          <Field name="startDate">
            {({ input, meta }) => (
              <Row>
                <Col sm={cols.first}>开始日期</Col>
                <Col sm={cols.second}>
                  {/* <Input {...input} placeholder="开始日期" /> */}
                  <DatePicker
                    value={input.value && moment(input.value, "YYYY-MM-DD")}
                    // onChange={(value) => input.onChange(value?.toDate())}
                    onChange={input.onChange}
                    // locale={locale}
                    // {...input}
                    //   {...{ ...input, value: moment(input.value) }}
                  />
                  {/* <span>
                    {input.value &&
                      moment(input.value, "YYYY-MM-DD").toString()}
                  </span> */}
                </Col>
              </Row>
            )}
          </Field>
          <Field name="endDate">
            {({ input, meta }) => (
              <Row>
                <Col sm={cols.first}>结束日期</Col>
                <Col sm={cols.second}>
                  <DatePicker
                    value={input.value && moment(input.value, "YYYY-MM-DD")}
                    onChange={input.onChange}
                    // onChange={(value) => input.onChange(value?.toDate())}
                  />
                </Col>
              </Row>
            )}
          </Field>
          <Field name="scope">
            {({ input, meta }) => (
              <Row>
                <Col sm={cols.first}>
                  招生范围
                  <br />
                  <Checkbox
                    indeterminate={
                      input.value?.length > 0 &&
                      input.value.length < provinces.length
                    }
                    checked={
                      input.value
                        ? input.value.length === provinces.length
                        : false
                    }
                    onChange={(e: CheckboxChangeEvent) =>
                      input.onChange(e.target.checked ? provinces : [])
                    }
                  >
                    全部选中
                  </Checkbox>
                </Col>
                <Col sm={cols.second}>
                  {/* <Input type="text" {...input} placeholder="结束日期" /> */}
                  {/* <DatePicker
                    value={input.value && moment(input.value, "YYYY-MM-DD")}
                    onChange={(value) => input.onChange(value?.toDate())}
                  /> */}
                  {/* <Select {...input} style={{ width: "20em" }}>
                    {provinces.map((province, index) => (
                      <Option key={index} value={province}>
                        {province}
                      </Option>
                    ))}
                  </Select> */}
                  {/* <CheckboxGroup
                    plainOptions={provinces}
                    defaultCheckedList={["上海"]}
                  /> */}
                  <Checkbox.Group
                    onChange={input.onChange}
                    value={input.value || []}
                    // {...input}
                  >
                    {provinces.map((value, index) => (
                      <Checkbox
                        key={index}
                        style={{ display: "flex", marginLeft: 0 }}
                        value={value}
                      >
                        {value}
                      </Checkbox>
                    ))}
                  </Checkbox.Group>
                </Col>
              </Row>
            )}
          </Field>
          <Field name="allowLateEnrollment" type="radiogroup">
            {({ input, meta }) => {
              return (
                <Row>
                  <Col sm={cols.first}>允许晚于结束日期的报名？</Col>
                  <Col sm={cols.second}>
                    <Radio.Group {...input}>
                      <Radio value={true} style={{ display: "block" }}>
                        允许
                      </Radio>
                      <Radio value={false} style={{ display: "block" }}>
                        不允许
                      </Radio>
                    </Radio.Group>
                  </Col>
                </Row>
              );
            }}
          </Field>
          <Field name="applyPhysicalConditionFilters" type="radiogroup">
            {({ input, meta }) => {
              return (
                <Row>
                  <Col sm={cols.first}>是否按身体条件筛选？</Col>
                  <Col sm={cols.second}>
                    <Paragraph>
                      <ul>
                        <li>动过手术?</li>
                        <li>色盲色弱?</li>
                        <li>过敏性鼻炎?</li>
                        <li>有纹身?</li>
                      </ul>
                    </Paragraph>
                    <Radio.Group {...input}>
                      <Radio value={true} style={{ display: "block" }}>
                        筛选
                      </Radio>
                      <Radio value={false} style={{ display: "block" }}>
                        不筛选
                      </Radio>
                    </Radio.Group>
                  </Col>
                </Row>
              );
            }}
          </Field>

          <Field name="interviewerList">
            {({ input, meta }) => {
              return (
                <Row>
                  <Col sm={cols.first}>面试官名单</Col>
                  <Col sm={cols.second}>
                    <Paragraph>
                      请输入手机号，每行一个；不同组用空行隔开；
                    </Paragraph>
                    {/* <Paragraph>不同组用空行隔开；</Paragraph> */}
                    <Paragraph>请确保输入正确，手机号用于登录；</Paragraph>
                    <Input.TextArea {...input} autoSize={{ minRows: 7 }} />
                  </Col>
                </Row>
              );
            }}
          </Field>
          <Field name="applySourceList" type="radiogroup">
            {({ input, meta }) => {
              return (
                <Row>
                  <Col sm={cols.first}>是否有预报名/初选清单？</Col>
                  <Col sm={cols.second}>
                    <Paragraph>不在清单上的考生，不能参加面试</Paragraph>
                    <Radio.Group {...input}>
                      <Radio value={true} style={{ display: "block" }}>
                        有
                      </Radio>
                      <Radio value={false} style={{ display: "block" }}>
                        没有
                      </Radio>
                    </Radio.Group>
                    {input.value && (
                      <Field name="sourceList">
                        {({ input, meta }) => (
                          <>
                            <Paragraph>
                              请输入考生身份证号，每行一个；
                            </Paragraph>
                            <Input.TextArea
                              {...input}
                              autoSize={{ minRows: 3, maxRows: 5 }}
                            />
                          </>
                        )}
                      </Field>
                    )}
                  </Col>
                </Row>
              );
            }}
          </Field>
          <Row>
            <Col offset={cols.first}>
              <Space>
                {/* <Button type="default" onClick={form.reset}>
                  重置
                </Button> */}
                <Button type="primary" htmlType="submit">
                  保存
                </Button>
                <Button
                  type="primary"
                  onClick={() => startInterview(token)(id)}
                >
                  启动面试（确认？启动后会初始化面试数据，设置不可再更改）
                </Button>
              </Space>
            </Col>
          </Row>
        </Space>
        <pre>{JSON.stringify(values, null, 2)}</pre>
      </form>
    )}
  </Form>
);

export default Details;
