export const fromActivityFormModel = (
  formModel: ActivityFormModel
): ActivityModel => ({
  ...formModel,
  scope: Array.isArray(formModel.scope) ? formModel.scope.join(",") : "",
});

export const toActivityFormModel = (
  model: ActivityModel
): ActivityFormModel => ({
  ...model,
  scope: typeof model.scope === "string" ? model.scope?.split(",") : [],
});
