export const apiUrl = process.env.REACT_APP_API_URL;

export const provinces = [
  "北京",
  "天津",
  "河北省",
  "山西省",
  "内蒙古自治区",
  "辽宁省",
  "黑龙江省",
  "吉林省",
  "上海",
  "江苏省",
  "浙江省",
  "安徽省",
  "福建省",
  "江西省",
  "山东省",
  "河南省",
  "湖北省",
  "湖南省",
  "广东省",
  "广西壮族自治区",
  "海南省",
  "重庆",
  "四川省",
  "贵州省",
  "云南省",
  "西藏自治区",
  "甘肃省",
  "陕西省",
  "青海省",
  "宁夏回族自治区",
  "新疆维吾尔族自治区",
  "台湾省",
  "香港特别行政区",
  "澳门特别行政区",
];
