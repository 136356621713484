import React from "react";
import { Typography } from "antd";
const { Text } = Typography;

const ShowError = ({ error }: { error: string }) => (
  <Text type="danger" style={{ marginLeft: ".5em" }}>
    {error}
  </Text>
);

export default ShowError;
