import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Spin, Result } from "antd";
import { FORM_ERROR } from "final-form";
import {
  getInterviewEvaluationList,
  updateEvaluationResult,
  generateFileSignedUrl,
  getInterviewEvaluationListForDebug,
} from "api/interview";
import { useToken } from "state/hooks";
import Evaluation from "./Evaluation";
import Dash from "./Dash";
import Video from "./Video";
import styles from "./index.module.scss";

const Interview = () => {
  const token = useToken();
  const [list, setList] = useState<InterviewEvaluationModel[]>([]); // 待评估列表
  const [urlList, setUrlList] = useState<Record<string, string>>({}); // 考生视频url
  const [index, setIndex] = useState(0);
  const totalRef = useRef(0);
  const abortCallbackRef = useRef<() => void>(); // 用于终止fetch网络请求

  useEffect(() => {
    const getList = async () => {
      // const { data, error } = await getInterviewEvaluationList(token)();
      const { data, error } = await getInterviewEvaluationListForDebug(token)(
        // "13761961022"
        "18801766128"
      );

      if (error) {
        console.log("error getList", error);
        return;
      }

      if (data?.length) {
        totalRef.current = data.length;
        setList(data.filter((item) => !item.evaluationResult));
      }
    };

    getList();
  }, [token]);

  useEffect(() => {
    const generateSignedUrl = async (index: number) => {
      if (index >= list.length) return;

      const accountId = list[index].accountId;

      // 已处理
      if (urlList[accountId]) return;

      const { data, error } = await generateFileSignedUrl(token)(
        accountId,
        list[index].fileName
      );

      if (error) {
        console.log("error generateFileSignedUrl", error);
        return;
      }

      if (data?.url) {
        setUrlList({ ...urlList, [accountId]: data.url });
      }
    };

    const run = async () => {
      if (list.length === 0) return;

      await generateSignedUrl(index);
      await generateSignedUrl(index + 1);
    };

    run();
  }, [token, list, index, urlList]);

  const handleSubmit = async (values: EvaluationFormValues) => {
    if (values.result) {
      const controller = new AbortController();
      abortCallbackRef.current = controller.abort;

      const { data, error } = await updateEvaluationResult(
        token,
        controller.signal
      )(list[index].id, values.result);

      abortCallbackRef.current = undefined;

      if (error) {
        console.log("error updateEvaluationResult", error);
        return { [FORM_ERROR]: "提交失败" };
      }

      if (data) {
        setList([...list.slice(0, index), data, ...list.slice(index + 1)]);
        setIndex(index + 1);
      }
    }

    return {};
  };

  const videoLink =
    list.length && index < list.length ? urlList[list[index].accountId] : "";

  return totalRef.current ? (
    list.length && index < list.length ? (
      <div>
        <div className={styles["side-box"]}>
          <Dash
            queueSize={list.length}
            queueIndex={index}
            total={totalRef.current}
            showDetails={true}
          />
        </div>
        <Row justify="center">
          {videoLink && (
            <Col>
              <Video
                key={list[index].accountId}
                fileName={list[index].fileName}
                src={videoLink}
              />
              <Row justify="center">
                <Evaluation
                  onSubmit={handleSubmit}
                  onAbort={() => {
                    abortCallbackRef.current && abortCallbackRef.current();
                  }}
                  values={{ result: list[index].evaluationResult }}
                />
              </Row>
            </Col>
          )}
        </Row>
      </div>
    ) : (
      <div className={styles.center}>
        <Result
          status="success"
          title="本次打分任务已全部完成"
          extra={[
            <Dash
              key="dash"
              queueSize={list.length}
              queueIndex={index}
              total={totalRef.current}
              showDetails={false}
            />,
          ]}
        />
      </div>
    )
  ) : (
    <div className={styles.center}>
      <Spin size="large" tip="正在加载。。。" />
    </div>
  );
};

export default Interview;
