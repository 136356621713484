import React, { useEffect, useState } from "react";
import { Button } from "antd";
import { getStats } from "api";
import { useToken } from "state/hooks";
import DownloadLink from "lib/DownloadLink";

interface Props {
  activityId: string;
  name: string;
}

const Download = ({ activityId, name }: Props) => {
  const token = useToken();
  const [href, setHref] = useState("");

  const getFile = async () => {
    const { data, error } = await getStats(token)(activityId);

    if (!error && data) {
      setHref(URL.createObjectURL(data));
    }
  };

  const getName = () => {
    const now = new Date();
    const datetiemStr = [
      [now.getFullYear()],
      now.getMonth() + 1,
      now.getDate(),
      now.getHours(),
      now.getMinutes(),
      now.getSeconds(),
    ]
      .map((n) => n.toString().padStart(2, "0"))
      .join("");

    return `${name}_${datetiemStr}`;
  };

  // release resource
  useEffect(() => {
    if (href) {
      return () => URL.revokeObjectURL(href);
    }
  }, [href]);

  return href ? (
    <DownloadLink autoDownload={false} href={href} filename={getName()}>
      点击下载
    </DownloadLink>
  ) : (
    <Button onClick={getFile}>导出Excel</Button>
  );
};

export default Download;
