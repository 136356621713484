import React, { ReactNode } from "react";
import { Layout } from "antd";
import Header from "./Header";
import Footer from "./Footer";
import styles from "./index.module.scss";

interface Props {
  activeItem: string;
  children: ReactNode;
  showHeader: boolean;
  showFooter: boolean;
}

const MyLayout = ({
  activeItem,
  children,
  showHeader = true,
  showFooter = true,
}: Props) => (
  <Layout className={styles.layout}>
    {showHeader && <Header activeItem={activeItem} />}
    <Layout.Content className={styles.content}>{children}</Layout.Content>
    {showFooter && <Footer />}
  </Layout>
);

export default MyLayout;
