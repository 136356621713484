import React from "react";
import { Form, Field } from "react-final-form";
import { Radio, Button, Typography } from "antd";
import { required } from "components/validators";
import ShowError from "components/ShowError";
import styles from "./index.module.scss";

const { Text } = Typography;

const grades = ["A", "B", "C", "D"];

interface Props {
  onSubmit: (values: EvaluationFormValues) => Promise<Object | undefined>;
  onAbort: () => void;
  values: EvaluationFormValues;
}

const Evaluation = ({ onSubmit, onAbort, values }: Props) => {
  return (
    <Form onSubmit={onSubmit} initialValues={values}>
      {({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit} className={styles.form}>
          {
            <Field name="result" validate={required} type="radiogroup">
              {({ input, meta }) => (
                <>
                  <Text className={styles.text}>打分：</Text>
                  <Radio.Group size="large" {...input}>
                    {grades.map((grade) => (
                      <Radio key={grade} value={grade}>
                        等第{grade}
                      </Radio>
                    ))}
                  </Radio.Group>
                  <Button
                    size="large"
                    type="primary"
                    htmlType="submit"
                    loading={submitting}
                    disabled={submitting}
                  >
                    提交
                  </Button>
                  {meta.touched && <ShowError error={meta.error} />}
                  <Button
                    className={styles.refresh}
                    size="large"
                    htmlType="button"
                    onClick={onAbort}
                  >
                    刷新
                  </Button>
                </>
              )}
            </Field>
          }
        </form>
      )}
    </Form>
  );
};

export default Evaluation;
