import React from "react";
import styles from "./index.module.scss";

const size = 640;

const Video = ({ fileName, src }: { fileName: string; src: string }) => {
  return (
    <div style={{ width: `${size}px` }}>
      <div
        style={{
          maxWidth: `${size}px`,
          maxHeight: `${size * 1.1}px`,
        }}
      >
        <video
          controls
          autoPlay
          style={{
            width: "100%",
            maxWidth: `${size}px`,
            maxHeight: `${size * 1.1}px`,
          }}
        >
          <source src={src} type="video/mp4" />
        </video>
      </div>
      <div className={styles["video-msg-box"]}>
        <a
          href={src}
          download={fileName}
          target="_blank"
          rel="noopener noreferrer"
        >
          <p className={styles.msg}>
            视频有时因播放器不兼容会出现黑屏或只有音频。
            <br />
            如遇文件无法播放，可尝试下载后播放：
            {fileName}
          </p>
        </a>
      </div>
    </div>
  );
};

export default Video;
