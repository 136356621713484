import React from "react";
import styled from "styled-components";
import { Button, Typography, Row } from "antd";
import moment from "moment";
import clsx from "clsx";
import styles from "./index.module.scss";

const { Title, Paragraph } = Typography;

interface Props {
  list: ActivityFormModel[];
  onSelect: (id: string) => void;
  onCreate: () => void;
  onRemove: (id: string) => void;
  currentId: string;
  className?: string;
}

const Master = ({
  list,
  onSelect,
  onCreate,
  onRemove,
  currentId,
  className,
}: Props) => {
  return (
    <div className={clsx(styles.main, className)}>
      <div className={styles.header}>
        <Title
          level={4}
          // className="title"
        >
          招生活动
        </Title>
        <Button type="primary" onClick={onCreate}>
          新增
        </Button>
      </div>
      {list?.length ? (
        <ul>
          {list.map(({ id, name, startDate }) => (
            <li
              key={id}
              onClick={() => onSelect(id)}
              className={id === currentId ? styles.active : undefined}
            >
              {typeof startDate === "string"
                ? new Date(startDate).toLocaleDateString()
                : startDate.toLocaleDateString()}
              {`-${name}`}
              <span
                onClick={(e) => {
                  e.preventDefault();
                  onRemove(id);
                }}
                className={styles.remove}
                role="img"
                aria-label="remove"
              >
                ❌
              </span>
            </li>
          ))}
        </ul>
      ) : (
        <div>暂无数据</div>
      )}
    </div>
  );
};

export default Master;
// export default styled(Master)`
//   .header .title {
//     font-size: 24px;
//   }

//   li.active,
//   li:hover {
//     font-size: 1.1em;
//     color: blue;
//     font-weight: bolder;
//   }

//   .remove {
//     cursor: pointer;
//     font-size: 0.85em;
//     padding: 0 1em;
//     visibility: hidden;
//   }

//   li:hover .remove {
//     visibility: visible;
//   }
// `;
