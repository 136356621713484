import { useRecoilValue, useSetRecoilState } from "recoil";
import { authState } from ".";

export const useIsAuthenticated = () => {
  const { token } = useRecoilValue(authState);

  return !!token;
};

export const useToken = () => {
  const { token } = useRecoilValue(authState);

  return token;
};

export const useUserName = () => {
  const { accountId } = useRecoilValue(authState);

  return accountId;
};

export const useLogout = () => {
  const setAuth = useSetRecoilState(authState);

  return () => setAuth({ token: "", accountId: "" });
};
