import React, { useCallback, useState } from "react";
import Interview from "./Interview";
import LetterOfCommitment from "./LetterOfCommitment";

const Container = () => {
  const [isConfirmed, setIsConfirmed] = useState(false);

  const confirm = useCallback(() => setIsConfirmed(true), []);

  return isConfirmed ? (
    <Interview />
  ) : (
    <LetterOfCommitment isOpen={isConfirmed} onConfirm={confirm} />
  );
};

export default Container;
