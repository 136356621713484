import React, { useState, useEffect } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { Form, Field } from "react-final-form";
import { FORM_ERROR } from "final-form";
import { Input, Row, Col, Button, Space, Typography } from "antd";
import { requestCode } from "api";
import {
  composeValidators,
  required,
  validateCellNumber,
} from "lib/validators";
import ShowError from "../ShowError";
import Logo from "./Logo";
const { Title, Text } = Typography;

const LoginForm = ({ login }: { login: LoginFunc }) => {
  const [isCounting, setIsCounting] = useState(false);
  const [countingDown, setCountingDown] = useState(0);

  useEffect(() => {
    if (isCounting) {
      const intervalId = setInterval(
        () =>
          setCountingDown((countingDown) => {
            if (countingDown - 1 === 0) setIsCounting(false);
            return countingDown - 1;
          }),
        1000
      );

      return () => clearInterval(intervalId);
    }
  }, [isCounting]);

  const handleSubmit = async ({
    account,
    password,
  }: {
    account: string;
    password: string;
  }) => {
    const { error } = await login(account, password);

    if (error) return { [FORM_ERROR]: "验证码不匹配" };
  };

  return (
    <Form onSubmit={handleSubmit}>
      {({
        handleSubmit,
        submitting,
        pristine,
        errors,
        values,
        submitError,
      }) => {
        const handleRequestCode = async () => {
          unstable_batchedUpdates(() => {
            setCountingDown(60);
            setIsCounting(true);
          });

          await requestCode(values.account);
        };
        return (
          <form
            onSubmit={handleSubmit}
            style={{ width: "100%", height: "100vh", backgroundColor: "white" }}
          >
            <div
              style={{
                width: "420px",
                margin: "10em auto 0",
              }}
            >
              <Row
                align="middle"
                style={{
                  padding: "3em 0",
                  width: "320px",
                  margin: "0 auto",
                }}
              >
                <Col>
                  <Logo />
                </Col>
                <Col>
                  <div style={{ paddingLeft: "1em" }}>
                    <Title level={4} style={{ margin: 0 }}>
                      上海民航职业技术学院
                      <br />
                      {new Date().getFullYear()}年招生在线打分系统
                    </Title>
                  </div>
                </Col>
              </Row>
              <div
                style={{
                  border: "2px dashed lightblue",
                  width: "420px",
                  margin: "0 auto",
                  padding: "1.25em",
                }}
              >
                <Space direction="vertical" style={{ width: "100%" }}>
                  <Row align="middle">
                    <Col span={3}>手机号</Col>
                    <Col flex="auto">
                      <Field
                        name="account"
                        validate={composeValidators(
                          required,
                          validateCellNumber
                        )}
                      >
                        {({ input, meta }) => (
                          <Row align="middle">
                            <Col flex="auto">
                              <Input {...input} placeholder="请输入手机号" />
                            </Col>
                            <Col>
                              {meta.touched && <ShowError error={meta.error} />}
                            </Col>
                          </Row>
                        )}
                      </Field>
                    </Col>
                  </Row>
                  <Row align="middle">
                    <Col span={3}>验证码</Col>
                    <Col flex="auto">
                      <Field
                        name="password"
                        validate={composeValidators(required)}
                      >
                        {({ input }) => (
                          <Row align="middle">
                            <Col flex="auto">
                              <Input {...input} placeholder="请输入验证码" />
                            </Col>
                            <Col>
                              <Field name="account">
                                {({ meta }) => (
                                  <Button
                                    type="primary"
                                    ghost
                                    onClick={handleRequestCode}
                                    disabled={isCounting || meta.error}
                                  >
                                    {isCounting
                                      ? `重新获取 ${countingDown}S`
                                      : "获取验证码"}
                                  </Button>
                                )}
                              </Field>
                            </Col>
                          </Row>
                        )}
                      </Field>
                    </Col>
                  </Row>
                  <Row justify="center">
                    <Button
                      size="large"
                      type="primary"
                      htmlType="submit"
                      block
                      disabled={
                        pristine ||
                        submitting ||
                        Object.keys(errors ?? {}).length > 0
                      }
                    >
                      登录
                    </Button>
                  </Row>
                  {submitError && (
                    <Row justify="center">
                      <Text type="danger">{submitError}</Text>
                    </Row>
                  )}
                </Space>
              </div>
            </div>
          </form>
        );
      }}
    </Form>
  );
};

export default LoginForm;
