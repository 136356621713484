import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { useRecoilState } from "recoil";
import "moment/locale/zh-cn"; // 时间locale设置
import { authState } from "state";
import { useIsAuthenticated } from "state/hooks";

// import routes from "./routes";
import Login from "components/Login";
import PrivateRoute from "components/PrivateRoute";
import Activity from "components/Activity";
import MyLayout from "components/Layout";
import Interview from "components/Interview";
import Progress from "components/Progress";

const App = () => {
  // const [{ token }, setAuth] = useRecoilState(authState);
  // const isAuthenticated = useIsAuthenticated();

  const [_, setAuth] = useRecoilState(authState);
  const token =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6IjE4NjIxODcyNjY1Iiwicm9sZSI6ImludGVydmlld2VyIiwibmJmIjoxNjQ4NjY3MDExLCJleHAiOjE2NDkyNzE4MTEsImlhdCI6MTY0ODY2NzAxMX0.EY4kRm9xlFKHf2xSnlQN3hZV8xbOa4fIsq5tr38YjFM";
  const isAuthenticated = true;
  useEffect(() => {
    setAuth({ token, accountId: "18621872665" });
  }, [setAuth]);

  return (
    <Router>
      <Switch>
        {/* {routes.map(({ isPrivate, ...routeProps }, index) =>
          isPrivate ? null : ( // <PrivateRoute key={index} {...routeProps} />
            <Route key={index} {...routeProps} />
          )
        )} */}
        <Route path="/" exact>
          <Redirect to={"/interview"} />
        </Route>
        <Route path="/login">
          <Login
            onLogin={(accountId, token) => {
              setAuth({ token, accountId });
            }}
            isAuthenticated={isAuthenticated}
          />
        </Route>
        <PrivateRoute path="/activity" isAuthenticated={isAuthenticated}>
          <MyLayout activeItem="/" showFooter={true} showHeader={true}>
            <Activity token={token} />
          </MyLayout>
        </PrivateRoute>
        <PrivateRoute path="/interview" isAuthenticated={isAuthenticated}>
          <MyLayout activeItem="/interview" showFooter={true} showHeader={true}>
            <Interview />
          </MyLayout>
        </PrivateRoute>
        <PrivateRoute path="/progress" isAuthenticated={isAuthenticated}>
          <MyLayout activeItem="/" showFooter={true} showHeader={true}>
            <Progress />
          </MyLayout>
        </PrivateRoute>
      </Switch>
    </Router>
  );
};

export default App;
