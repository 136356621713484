import { apiUrl } from "config";

export const getActivity = (token: string) => async (id: string) => {
  const url = `/activity/${id}`;
  const headers = {
    Accept: "application/json",
  };

  const res = await fetch(apiUrl + url, {
    method: "GET",
    headers: token ? { ...headers, Authorization: `Bearer ${token}` } : headers,
    referrerPolicy: "no-referrer",
    mode: "cors",
    cache: "no-cache",
    credentials: "omit",
  });

  if (res.status === 200) {
    const resJson: ActivityModel = await res.json();

    return { error: "", data: resJson };
  }

  return { error: res.statusText };
};

export const getActivityList = (token: string) => async () => {
  const url = "/activity";
  const headers = {
    Accept: "application/json",
  };

  const res = await fetch(apiUrl + url, {
    method: "GET",
    headers: token ? { ...headers, Authorization: `Bearer ${token}` } : headers,
    referrerPolicy: "no-referrer",
    mode: "cors",
    cache: "no-cache",
    credentials: "omit",
  });

  if (res.status === 200) {
    const resJson: ActivityModel[] = await res.json();

    return { error: "", data: resJson };
  }

  return { error: res.statusText };
};

export const createActivity =
  (token: string) => async (activity: ActivityModel) => {
    const url = "/activity";
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    const res = await fetch(apiUrl + url, {
      method: "POST",
      headers: token
        ? { ...headers, Authorization: `Bearer ${token}` }
        : headers,
      referrerPolicy: "no-referrer",
      mode: "cors",
      cache: "no-cache",
      credentials: "omit",
      body: JSON.stringify(activity),
    });

    if (res.status === 200) {
      const resJson: ActivityModel = await res.json();

      return { error: "", data: resJson };
    }

    return { error: res.statusText };
  };

export const updateActivity =
  (token: string) => async (id: string, activity: ActivityModel) => {
    const url = `/activity/${id}`;
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    const res = await fetch(apiUrl + url, {
      method: "PUT",
      headers: token
        ? { ...headers, Authorization: `Bearer ${token}` }
        : headers,
      referrerPolicy: "no-referrer",
      mode: "cors",
      cache: "no-cache",
      credentials: "omit",
      body: JSON.stringify(activity),
    });

    if (res.status === 200) {
      const resJson: ActivityModel = await res.json();

      return { error: "", data: resJson };
    }

    return { error: res.statusText };
  };

export const startInterview = (token: string) => async (id: string) => {
  // const url = "/activity/start";
  const url = `/activity/start/${id}`;
  const headers = {
    // "Content-Type": "application/json",
    Accept: "application/json",
  };

  const res = await fetch(apiUrl + url, {
    method: "POST",
    headers: token ? { ...headers, Authorization: `Bearer ${token}` } : headers,
    referrerPolicy: "no-referrer",
    mode: "cors",
    cache: "no-cache",
    credentials: "omit",
    // body: JSON.stringify({ interviewId: id }),
  });

  if (res.status === 200) {
    const resJson = await res.json();

    return { error: "", data: resJson };
  }

  return { error: res.statusText };
};

export const getInterviewProgress = (token: string) => async () => {
  try {
    const url = `/activity/interviewprogress`;
    const headers = {
      Accept: "application/json",
    };

    const res = await fetch(apiUrl + url, {
      method: "GET",
      headers: token
        ? { ...headers, Authorization: `Bearer ${token}` }
        : headers,
      referrerPolicy: "no-referrer",
      mode: "cors",
      cache: "no-cache",
      credentials: "omit",
    });

    if (res.ok) {
      const resJson: Array<InterviewProgressResult> = await res.json();

      return { error: "", data: resJson };
    }

    return { error: res.statusText };
  } catch (err) {
    return { error: String(err) };
  }
};

export const updateInterviewResults =
  (token: string) => async (activityId: string) => {
    try {
      const url = `/activity/updateResults/${activityId}`;
      const headers = {
        Accept: "application/json",
      };

      const res = await fetch(apiUrl + url, {
        method: "POST",
        headers: token
          ? { ...headers, Authorization: `Bearer ${token}` }
          : headers,
        referrerPolicy: "no-referrer",
        mode: "cors",
        cache: "no-cache",
        credentials: "omit",
      });

      if (res.ok) {
        const resJson: { url: string } = await res.json();

        return { error: "", data: resJson };
      }

      return { error: res.statusText };
    } catch (err) {
      return { error: String(err) };
    }
  };

// Stats
export const getStats = (token: string) => async (activityId: string) => {
  try {
    const url = `/activity/stats/${activityId}`;
    const headers = {
      Accept: "application/json",
    };

    const res = await fetch(apiUrl + url, {
      method: "GET",
      headers: token
        ? { ...headers, Authorization: `Bearer ${token}` }
        : headers,
      referrerPolicy: "no-referrer",
      mode: "cors",
      cache: "no-cache",
      credentials: "omit",
    });

    if (res.ok) {
      const resBlob: Blob = await res.blob();
      return { error: "", data: resBlob };
    }

    return { error: res.statusText };
  } catch (err) {
    return { error: String(err) };
  }
};

/**
 * 获取验证码，验证码直接发送到用户手机
 * @param accountId 手机号
 */
export const requestCode = async (accountId: string) => {
  const url = "/account/code";
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };

  const res = await fetch(apiUrl + url, {
    method: "POST",
    headers,
    referrerPolicy: "no-referrer",
    mode: "cors",
    cache: "no-cache",
    credentials: "omit",
    body: JSON.stringify({ accountId }),
  });

  if (res.status === 200) return { error: "" };

  return {
    error: res.statusText,
  };
};

export const login: LoginFunc = async (account: string, password: string) => {
  const url = "/account/internal/login";
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };

  const res = await fetch(apiUrl + url, {
    method: "POST",
    headers,
    referrerPolicy: "no-referrer",
    mode: "cors",
    cache: "no-cache",
    credentials: "omit",
    body: JSON.stringify({ account, password }),
  });

  if (res.status === 200) {
    const resJson: { token: string } = await res.json();
    const { token } = resJson;
    return { error: "", token };
  }

  return {
    error: res.statusText,
  };
};
