import React, { useEffect } from "react";
import { Modal } from "antd";
import styles from "./index.module.scss";

interface Props {
  isOpen: boolean;
  onConfirm: () => void;
}

const LetterOfCommitment = ({ isOpen, onConfirm }: Props) => {
  useEffect(() => {
    if (!isOpen)
      Modal.info({
        title: "空中乘务和民航空中安全保卫专业线上面试考官诚信承诺书",
        content: (
          <div>
            <p>
              作为一名面试评分教师，自愿接受学院的聘请，承担
              {" " + new Date().getFullYear() + " "}
              年学院空中乘务和民航空中安全保卫专业线上面试评分工作。为了公平、公正地做好本次线上面试评分工作，本人郑重承诺如下：
            </p>
            <p>1.本人符合回避关系的规定，无任何亲属参加本次面试；</p>
            <p>
              2.严格按照面试评分要求，独立打分，公平把握评分尺度，
              对每一位考生的评分做到客观、公正、准确；
            </p>
            <p>3.每天保质保量完成相应工作量，并在完成后在工作群中及时反馈；</p>
            <p>
              4.自觉遵守考试纪律和考务工作规定，评分期间自觉做到不与外界联系，自觉接受纪律监督；
            </p>
            <p>
              5.严格遵守保密规定，不得向任何人透露“面试测评标准”等与面试评分相关的内容。
            </p>
          </div>
        ),

        onOk: onConfirm,
        wrapClassName: styles.modal,
        mask: true,
        width: "auto",
        maskStyle: { backgroundColor: "rgba(72,72,72,1)" },
      });
  }, [isOpen, onConfirm]);

  return isOpen ? <span></span> : null;
};

export default LetterOfCommitment;
