import React, { ReactNode, useEffect, useRef } from "react";

interface Props {
  autoDownload: boolean;
  href: string;
  filename?: string;
  children?: ReactNode;
}

const DownloadLink = ({ autoDownload, href, filename, children }: Props) => {
  const linkEl = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    if (autoDownload && href) setTimeout(() => linkEl.current?.click(), 200);
  }, [autoDownload, href]);

  return (
    <a
      ref={linkEl}
      href={href}
      download={filename}
      //   data-downloadurl={["text/sgf", sgfFile, href].join(":")}
    >
      {children}
    </a>
  );
};

export default DownloadLink;
