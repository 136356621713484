import React from "react";
import styled from "styled-components";

const Logo = ({ className }: { className?: string }) => (
  <div className={className}>
    <img src="logo.jpg" className="logo" alt="logo"></img>;
  </div>
);

export default styled(Logo)`
  .logo {
    display: inline-block;
    margin-top: 0.14285714em;
    width: 3.5rem;
    height: auto;
    vertical-align: middle;
  }
`;
