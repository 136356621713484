import React from "react";
import { Layout } from "antd";
import styles from "./index.module.scss";

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <Layout.Footer className={styles.footer}>
      <div>
        © {year === 2020 ? "2020" : `2020 - ${year}`} 上海民航 招生在线打分系统
        管理后台
      </div>
    </Layout.Footer>
  );
};

export default Footer;
