import { apiUrl } from "config";

export const getInterviewEvaluationListForDebug =
  (token: string) => async (interviewId: string) => {
    try {
      const url = `/interview/${interviewId}`;
      const headers = {
        Accept: "application/json",
      };

      const res = await fetch(apiUrl + url, {
        method: "GET",
        headers: token
          ? { ...headers, Authorization: `Bearer ${token}` }
          : headers,
        referrerPolicy: "no-referrer",
        mode: "cors",
        cache: "no-cache",
        credentials: "omit",
      });

      if (res.ok) {
        const resJson: InterviewEvaluationModel[] = await res.json();

        return { error: "", data: resJson };
      }

      return { error: res.statusText };
    } catch (err) {
      return { error: String(err) };
    }
  };

export const getInterviewEvaluationList = (token: string) => async () => {
  try {
    const url = "/interview";
    const headers = {
      Accept: "application/json",
    };

    const res = await fetch(apiUrl + url, {
      method: "GET",
      headers: token
        ? { ...headers, Authorization: `Bearer ${token}` }
        : headers,
      referrerPolicy: "no-referrer",
      mode: "cors",
      cache: "no-cache",
      credentials: "omit",
    });

    if (res.ok) {
      const resJson: InterviewEvaluationModel[] = await res.json();

      return { error: "", data: resJson };
    }

    return { error: res.statusText };
  } catch (err) {
    return { error: String(err) };
  }
};

export const updateEvaluationResult =
  (token: string, signal?: AbortSignal) =>
  async (id: string, result: string) => {
    try {
      const url = `/interview/${id}`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
      };

      const res = await fetch(apiUrl + url, {
        method: "PUT",
        headers: token
          ? { ...headers, Authorization: `Bearer ${token}` }
          : headers,
        signal,
        referrerPolicy: "no-referrer",
        mode: "cors",
        cache: "no-cache",
        credentials: "omit",
        body: JSON.stringify(result),
      });

      if (res.ok) {
        const resJson: InterviewEvaluationModel = await res.json();

        return { error: "", data: resJson };
      }
      return { error: res.statusText };
    } catch (err) {
      return { error: String(err) };
    }
  };

export const generateFileSignedUrl =
  (token: string) => async (accountId: string, fileName: string) => {
    try {
      const url = `/interview/GenerateFileSignedUrl/${accountId}`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
      };

      const res = await fetch(apiUrl + url, {
        method: "POST",
        headers: token
          ? { ...headers, Authorization: `Bearer ${token}` }
          : headers,
        referrerPolicy: "no-referrer",
        mode: "cors",
        cache: "no-cache",
        credentials: "omit",
        body: JSON.stringify({ fileName }),
      });

      if (res.ok) {
        const resJson: { url: string } = await res.json();

        return { error: "", data: resJson };
      }

      return { error: res.statusText };
    } catch (err) {
      return { error: String(err) };
    }
  };
