import React from "react";
import { Redirect, useLocation } from "react-router";
import * as api from "api";
import LoginForm from "./LoginForm";
import styles from "./index.module.scss";

const Login = ({
  isAuthenticated,
  onLogin,
}: // location,
{
  isAuthenticated: boolean;
  onLogin: (accountId: string, token: string) => void;
  // location:
}) => {
  const login: LoginFunc = async (account: string, password: string) => {
    const { error, token } = await api.login(account, password);

    if (error) {
      console.log("login error", error);
      return { error };
    }

    token && onLogin(account, token);
    return { error: "", token };
  };

  const location = useLocation<{ from?: string }>();
  const from = location.state?.from;
  const to = !from || location.pathname === from ? "/" : from;

  // const from = (location.state && location.state.from) || "/portal";
  if (isAuthenticated) return <Redirect to={to} />;

  return <LoginForm login={login} />;
};

export default Login;
