import React from "react";
import { Link } from "react-router-dom";
import { Layout, Menu } from "antd";
import { useIsAuthenticated } from "state/hooks";
import Logo from "./Logo";
import LoginStatus from "./LoginStatus";
import styles from "./index.module.scss";

const { Header } = Layout;

const links = [
  // { name: "首页配置", to: "/home" },
  // { name: "招生活动", to: "/activity" },
  { name: "面试评估", to: "/interview" },
  // { name: "面试进度", to: "/progress" },
];

interface Props {
  activeItem: string;
}

const AppHeader = ({ activeItem = "/" }: Props) => {
  const isAuthenticated = useIsAuthenticated();

  return isAuthenticated ? (
    <div className={styles.header}>
      <div className={styles["logo-container"]}>
        <Logo />
        <span className={styles.company}>上海民航招生在线打分系统</span>
      </div>
      <Menu
        className={styles.nav}
        theme="dark"
        mode="horizontal"
        defaultSelectedKeys={["0"]}
      >
        <Menu.Item key="logo"></Menu.Item>
        {links.map((link, index) => (
          <Menu.Item key={index}>
            <Link to={link.to}>{link.name}</Link>
          </Menu.Item>
        ))}
      </Menu>
      <LoginStatus />
    </div>
  ) : null;
};

export default AppHeader;
