import React, { useEffect, useState } from "react";
import { Row, Col } from "antd";
import { fromActivityFormModel, toActivityFormModel } from "models";
import { getActivityList, createActivity, updateActivity } from "api";
import Master from "./Master";
import Details from "./Details";

// const activity: ActivityModel = {
//   id: "",
//   name: "山东省招生",
//   description: "山东省招生",
//   startDate: new Date("2020-04-01"),
//   endDate: new Date("2020-05-13"),
//   scope: "上海",
//   allowLateEnrollment: true,
//   applyPhysicalConditionFilters: false,
//   applySourceList: false,
//   sourceList: "",
//   status: "",
//   createdAt: new Date(),
//   updatedAt: new Date(),
// };

const Activity = ({ token }: { token: string }) => {
  const [list, setList] = useState<ActivityFormModel[]>([]);
  const [currentId, setCurrentId] = useState("");

  useEffect(() => {
    getAll();
  }, []);

  const getAll = async () => {
    const { data } = await getActivityList(token)();

    if (Array.isArray(data))
      setList(data.map((model) => toActivityFormModel(model)));
  };

  // activity 不允许删除
  const del = async (id: string) => {
    // await api.del(id, token);
    // if (currentId === id) setCurrentId(null);
    // await getAll();
    if (currentId === id) setCurrentId("");
    setList(list.filter((a) => a.id !== id));
  };

  const update = async (entity: ActivityFormModel) => {
    await updateActivity(token)(entity.id, fromActivityFormModel(entity));
    await getAll();
  };

  const create = async (entity: ActivityFormModel) => {
    // const {
    //   data: { id },
    // } = await api.create(entity, token);
    // await getAll();
    // setCurrentId(id);

    const { data, error } = await createActivity(token)(
      fromActivityFormModel(entity)
    );
    if (!error && data) {
      setCurrentId(data.id);
      await getAll();
    }
  };

  const getObj = () => {
    if (currentId) {
      const obj = list.find(({ id }) => id === currentId);
      if (obj) {
        return obj;
      }
    }
    return undefined;
  };

  const onSubmit = async (values: ActivityFormModel) => {
    if (currentId) {
      await update({
        ...values,
        id: currentId,
      });
    } else {
      await create(values);
    }
  };

  return (
    // <Container fluid style={{ width: "90%" }}>
    <Row>
      <Col span={6}>
        <Master
          list={list}
          currentId={currentId}
          onSelect={(id) => setCurrentId(id)}
          onCreate={() => setCurrentId("")}
          onRemove={(id) => {
            if (window.confirm("确认删除？")) del(id);
          }}
        />
      </Col>
      <Col span={1} />
      <Col span={11}>
        <Details
          id={currentId}
          obj={getObj()}
          onSubmit={onSubmit}
          token={token}
          //  startInterview={startInterview}
        />
      </Col>
    </Row>
    // </Container>
  );
};

export default Activity;
