import React, { useEffect, useState } from "react";
import {
  // Typography,
  Table,
  Space,
  Button,
} from "antd";

import {
  //  fromActivityFormModel,
  toActivityFormModel,
} from "models";
import {
  getActivityList,
  // createActivity,
  // updateActivity,
  getInterviewProgress,
  updateInterviewResults,
  getStats,
} from "api";
import { useToken } from "state/hooks";
import Download from "./Download";

// const { Title } = Typography;
const { Column } = Table;

const Progress = () => {
  const [list, setList] = useState<ActivityFormModel[]>([]);
  const [progress, setProgress] =
    useState<Record<string, InterviewProgressResult>>();
  const token = useToken();

  useEffect(() => {
    const getAll = async () => {
      const { data } = await getActivityList(token)();

      if (Array.isArray(data))
        setList(data.map((model) => toActivityFormModel(model)));
    };

    getAll();
  }, [token]);

  useEffect(() => {
    const getProgress = async () => {
      const { data } = await getInterviewProgress(token)();
      if (!data) return;

      const record = data.reduce(
        (result, current) => ({ ...result, [current.activityId]: current }),
        {} as Record<string, InterviewProgressResult>
      );

      setProgress(record);

      console.log({ record });
    };

    getProgress();
  }, [token]);

  const updateResults = async (activityId: string) => {
    const { error, data } = await updateInterviewResults(token)(activityId);
    if (error) console.log("error", error);
    if (data) console.log("data", data);
  };

  return (
    <Table<ActivityFormModel> dataSource={list} rowKey="id">
      <Column title="名称" dataIndex="name" key="name" />
      {/* <Column title="描述" dataIndex="description" key="description" /> */}
      <Column
        title="开始日期/结束日期"
        key="startDate"
        render={(value, record: ActivityFormModel) => {
          const { startDate, endDate } = record;
          return (
            <span>
              {typeof startDate === "string"
                ? new Date(startDate).toLocaleDateString()
                : startDate.toLocaleDateString()}{" "}
              -{" "}
              {typeof endDate === "string"
                ? new Date(endDate).toLocaleDateString()
                : endDate.toLocaleDateString()}
            </span>
          );
        }}
      />
      {/* <Column title="结束日期" dataIndex="endDate" key="endDate" />
        <Column title="招生范围" dataIndex="scope" key="scope" /> */}
      <Column
        title="面试人数"
        render={({ id }) => progress?.[id]?.candidateCount ?? "N/A"}
        key="activityId"
      />
      <Column
        title="进度"
        render={({ id }) => {
          const p = progress?.[id];
          if (!p) return "N/A";
          const { evaluatedCount, evaluationCount } = p;
          return evaluationCount
            ? `${(evaluatedCount / evaluationCount) * 100}%`
            : "N/A";
        }}
      />
      <Column title="状态" dataIndex="status" key="status" />
      <Column
        title="操作"
        render={(text, record: ActivityFormModel) => (
          <Space>
            {/* <Button onClick={() => getStats(token)(record.id)}>
              导出Excel
            </Button> */}
            <Download activityId={record.id} name={record.name} />
            {record.id}
            <Button onClick={() => updateResults(record.id)}>统计结果</Button>
          </Space>
        )}
      />
    </Table>
  );
};

export default Progress;
